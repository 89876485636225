import React, { useState, useEffect, useRef } from "react"
import { animated, useSpring } from 'react-spring';

const ResourceVariant = ({variant, style}) => {
	const [isBooped, setIsBooped] = useState(false)
	const timing = 150

	let className = isBooped ? style.boop : ""

	const trigger = () => {
		console.log("Trigger");
		setIsBooped(true)
	}

	useEffect(() => {
		if(!isBooped){
			return;
		}
		const timeoutId = window.setTimeout(() => {
			setIsBooped(false)
		}, timing)
	}, [isBooped, timing])

	// const boopStyle = useSpring({
	// 	transform: isBooped
	// 	? `scale(1.5)`
	// 	: `scale(1)`,
	// 	config: {
	// 		tension: 300,
	// 		friction: 10,
	// 	},
	// })
	const someStyle = useSpring({
		'transform': `scale(${isBooped ? 1.05 : 1})`,
		config: {
			tension: 200,
			friction: 10,
		},
	})

	return (
		<a key={variant.name} href={variant.link} className={`${style.variant} ${variant.highlight ? style.highlight : ""}`} onMouseEnter={trigger}>
			<span className={style.variant_name}>{variant.name}</span>
			<span className={style.variant_feature}>{variant.feature}</span>
			<span className={style.variant_price}>{variant.price} €</span>
			<span className={style.variant_cta}>{variant.cta}</span>
			<animated.div className={style.background} style={someStyle}></animated.div>
		</a>
	);
}
 
export default ResourceVariant;