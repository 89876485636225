import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from "react"
import MetaHead from "../components/MetaHead";
import {graphql, Link} from "gatsby"
import Layout from "../components/Layout";
import GatsbyImage from 'gatsby-image';
import style from "../styles/Resource.module.scss"
import ResourceVariant from "../components/ResourceVariant"

const Resource = ({data, pageContext, path}) => {
	const article = data.contentfulResources;
	const essentialData = data.contentfulEssentialData
	const document = JSON.parse(article.content.raw)

	const options = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const id = node.data.target.sys.id
				const image = article.content.references.find((entry) => entry.contentful_id === id)

				if(image.file.contentType === "image/svg+xml"){
					return <img src={image.file.url} alt={image.title} />
				}else {
					return <GatsbyImage fluid={image.fluid} alt={image.title} />
				}
			},
			[BLOCKS.EMBEDDED_ENTRY]: (node) => {
					const id = node.data.target.sys.id
					const element = article.content.references.find((entry) => entry.contentful_id === id)

					return <div dangerouslySetInnerHTML={{__html: element.markdown.childMarkdownRemark.html}}></div>
			},
			[INLINES.EMBEDDED_ENTRY]: (node) => {
					const id = node.data.target.sys.id
					const element = article.content.references.find((entry) => entry.contentful_id === id)

					return <div dangerouslySetInnerHTML={{__html: element.markdown.childMarkdownRemark.html}}></div>
			},
		}
	}

	return (
		<>
			<MetaHead 
				meta={{
					title: article.title,
					description: article.description,
					og_type: "article",
					ob_image: article.blog_thumbnail
				}} lang={pageContext.locale} path={path} />
			<Layout essentialData={{...essentialData, langKey: pageContext.locale, path}}>
				<div className="breadcrumbs">
					<Link to={`/${pageContext.locale}`}>Home</Link> &gt; <Link to={`/${pageContext.locale}/resources/`}>Resources</Link> &gt; {article.title}
				</div>
				<h1>{article.title}</h1>
				<div className="blog_container">
					<div className="blog_content">{documentToReactComponents(document, options)}</div>
				</div>
				<h2>Variants</h2>
				<div className={style.variantContainer}>
					{
						article.variants.map((variant) => {
							return (
								<ResourceVariant variant={variant} style={style} />
							)
						})
					}
				</div>
				<div className="blog_dates">
					<span>created: {article.createdAt}</span>
					<span>last updated: {article.updatedAt}</span>
				</div>
			</Layout>
		</>
	);
}


if(typeof window !== 'undefined'){
	
}

export const query = graphql`
query($slug: String!, $locale: String!) {
	contentfulResources(node_locale: {eq: $locale}, slug: {eq: $slug}) {
		content {
		  raw
		  references {
					... on ContentfulAsset {
						contentful_id
						title
						fluid(quality:85) {
							...GatsbyContentfulFluid
						}
						file {
							url
							contentType
						}
					}
				}
		}
		title
		price
		description {
			description
		}
		updatedAt(formatString: "DD.MM.YYYY HH:MM")
		createdAt(formatString: "DD.MM.YYYY HH:MM")
		variants {
			price
			link
			name
			highlight
			feature
			cta
		}
		description {
			description
		}
	}
	contentfulEssentialData(node_locale: {eq: $locale}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
}`

export default Resource;